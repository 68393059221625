/*
 *** Event Tracking ***
 To add event tracking to an html element use the following attributes:
 Required: il-analytics, il-analytics-event, il-analytics-category
 Optional: il-analytics-label, il-analytics-value
 il-analytics
 il-analytics-event="EVENT_NAME_HERE"
 il-analytics-category="EVENT_CATEGORY_HERE"
 il-analytics-label="OPTIONAL_ADDITIONAL_INFO"
 il-analytics-value="OPTIONAL_INTEGER_NOT_USED"

 To add event tracking in javascript reference the module 'api.analytics'
 in your module and the object Analytics in your controller:
 Ex: Analytics.trackEvent('EVENT_NAME_HERE', 'EVENT_CATEGORY_HERE');

 *** Page tracking ***
 Page view and route tracking is automatically added on a state/route change.

 */

(function(undefined) {
	angular
		.module('api.analytics', ['api.application.user'])
		.provider('Analytics', analyticsProvider)
		.directive('ilAnalytics', analyticsDirective);

	/**
	 * Analytics provider
	 */

	function analyticsProvider() {
		var provider = this;

		// provider.googleAnalyticsTrackingID;
		// provider.employeeGoogleAnalyticsTrackingID;

		provider.$get = analyticsFactory;

		analyticsFactory.$inject = ['$document', '$location', '$rootScope', '$window', 'ApplicationUserService'];

		function analyticsFactory($document, $location, $rootScope, $window, ApplicationUserService) {
			var analytics = this;
			// analytics.name = 't0'; // Default GA tracker name

			// var employeeRegex = /@imaginelearning\.com/i; // Used to identify employee users

			// //google's universal analytics allows for user defined "dimensions" & "metrics"
			// var dimensions, metrics;
			// analytics.summary = undefined;
			// analytics.dimensions = undefined;
			// analytics.metrics = undefined;

			// //build custom dimensions
			// var getDimensions = function() {
			// 	analytics.dimensions = {};

			// 	//dimension1: user role
			// 	var user = ApplicationUserService.getUser();
			// 	if (user && user.userRole) {
			// 		analytics.dimensions.dimension1 = user.userRole;
			// 	}
			// };

			// //build custom metrics
			// var getMetrics = function() {
			// 	analytics.metrics = {};
			// };

			// //get dimensions and metrics as a single object to append to report
			// var getDetails = function() {
			// 	analytics.summary = undefined;
			// 	getDimensions();
			// 	getMetrics();
			// 	analytics.summary = $.merge(analytics.dimensions, analytics.metrics);
			// 	analytics.pageName = $location.url();
			// };

			analytics.trackEvent = function(eventName, category, label, value) {
				// if (!$window.ga || !angular.isFunction($window.ga)) {
				// 	return;
				// }
				// getDetails();
				// $window.ga(analytics.name + '.send', 'event', category, eventName, label, value, analytics.summary);
				// //console.log('analytics.trackEvent');
				// //console.log(eventName);
				// //console.log(category);
				// //console.log(value);
				// //console.log(analytics.summary);
			};

			analytics.trackPage = function(pageName) {
				// if (!$window.ga || !angular.isFunction($window.ga)) {
				// 	return;
				// }
				// getDetails();
				// $window.ga(analytics.name + '.send', 'pageview', pageName, analytics.summary);
				// //console.log('analytics.trackPage');
				// //console.log(pageName);
				// //console.log(analytics.summary);
			};

			//captures all state and route changes
			analytics.trackCurrent = function() {
				// if (!$window.ga || !angular.isFunction($window.ga)) {
				// 	return;
				// }
				// getDetails();
				// $window.ga(analytics.name + '.send', 'pageview', analytics.pageName, analytics.summary);
				// //console.log('analytics.trackCurrent');
				// //console.log(analytics.pageName);
				// //console.log(analytics.summary);
			};

			analytics.initialize = function() {
				// (function(window, document, tagName, scriptSrc, gaVar, gaScriptNode, firstScriptNode) {
				// 	if (!window[gaVar]) {
				// 		window['GoogleAnalyticsObject'] = gaVar;
				// 		window[gaVar] =
				// 			window[gaVar] ||
				// 			function() {
				// 				(window[gaVar].q = window[gaVar].q || []).push(arguments);
				// 			};
				// 		window[gaVar].l = 1 * new Date();
				// 		gaScriptNode = document.createElement(tagName);
				// 		gaScriptNode.async = true;
				// 		gaScriptNode.src = scriptSrc;
				// 		firstScriptNode = document.getElementsByTagName(tagName)[0];
				// 		firstScriptNode.parentNode.insertBefore(gaScriptNode, firstScriptNode);
				// 	}
				// })($window, $document[0], 'script', '//www.google-analytics.com/analytics.js', 'ga');
				// analytics.createTracker();
			};

			// analytics.createTracker = function() {
			// 	// See if we have a logged in user, and if so, if that user is an IL employee
			// 	var user = ApplicationUserService.getUser(),
			// 		id = provider.googleAnalyticsTrackingID;
			// 	if (user && employeeRegex.test(user.username)) {
			// 		analytics.name = 't_employee';
			// 		id = provider.employeeGoogleAnalyticsTrackingID;
			// 	} else {
			// 		analytics.name = 't0';
			// 	}

			// 	// If tracker doesn't already exist by that name, create it
			// 	if ($window.ga && (!$window.ga.getByName || !$window.ga.getByName(analytics.name))) {
			// 		$window.ga('create', id, 'auto', { name: analytics.name });
			// 		$window.ga(analytics.name + '.send', 'pageview');
			// 	}
			// };

			// $rootScope.$on('$stateChangeSuccess', function() {
			// 	analytics.trackCurrent();
			// });

			// $rootScope.$on('$routeChangeSuccess', function(event, current) {
			// 	if (current && (current.$$route || current).redirectTo) {
			// 		return;
			// 	}
			// 	analytics.trackCurrent();
			// });

			// $rootScope.$on('Analytics.trackEvent', function(event, params) {
			// 	analytics.trackEvent(params.eventName, params.category, params.label, params.value);
			// });

			return analytics;
		}
	}

	/**
	 * ilAnalytics directive
	 */

	analyticsDirective.$inject = ['Analytics'];

	function analyticsDirective(Analytics) {
		return {
			link: function(scope, elem, attrs) {
				// //decorate ilAnalytics with the following attributes
				// //ilAnalyticsEvent: event name - required
				// //ilAnalyticsCategory: event category - required
				// //ilAnalyticsLabel: event label - optional - extra detail on the event if needed
				// //ilAnalyticsValue: event value - optional - an integer value - currently not used for anything
				// elem.on('click', function() {
				// 	// allow passing CATEGORY, EVENT, LABEL in single attr
				// 	var event, category, label;
				// 	if (attrs.ilAnalytics && attrs.ilAnalytics.length) {
				// 		var s = attrs.ilAnalytics.split(',');
				// 		if (s && s.length > 1) {
				// 			category = s[0] && s[0].length ? s[0].trim() : undefined;
				// 			event = s[1] && s[1].length ? s[1].trim() : undefined;
				// 			label = s.length > 2 && s[2] && s[2].length ? s[2].trim() : undefined;
				// 		}
				// 	}
				// 	Analytics.trackEvent(
				// 		event || attrs.ilAnalyticsEvent,
				// 		category || attrs.ilAnalyticsCategory,
				// 		label || attrs.ilAnalyticsLabel,
				// 		attrs.ilAnalyticsValue
				// 	);
				// });
				// elem.on('$destroy', function() {
				// 	elem.off('click');
				// });
			}
		};
	}
})();
