angular
	.module('api.assessment.provider', ['api.urls', 'api.organization', 'product.names'])
	.constant('AssessmentProviders', {
		ATI: 'ATI',
		ILE: 'ILE',
		NWEA: 'NWEA'
	})
	.constant('AssessmentProvidersScreenNames', {
		ATI: 'Galileo ELA Benchmark',
		ILE: 'Embedded Placement',
		NWEA: 'NWEA MAP Reading'
	})
	.service('AssessmentProvider', AssessmentProviderService);

AssessmentProviderService.$inject = ['$q', 'ApiProducts', 'AssessmentProviders', 'Organization'];
function AssessmentProviderService($q, ApiProducts, AssessmentProviders, Organization) {
	this.getForOrgAndChildren = getForOrgAndChildren;

	////////////////////

	function getForOrgAndChildren(orgId, grade) {
		return $q
			.all([
				Organization.getInheritedSettings(orgId).then(settings => ({ settings })),
				Organization.children(orgId, { includeDescendants: true, additionalFields: ['settings'] })
			])
			.then(([org, { items: orgs }]) => {
				const allOrgs = [org, ...orgs];
				const gradeOverrides = _.get(org, 'settings.AssessmentProviderGradeOverride', []).reduce((map, override) => {
					let gradeOverride = override.split(':');
					map[gradeOverride[0]] = gradeOverride[1];
					return map;
				}, {});

				let assessmentProvider = _.get(org, 'settings.AssessmentProvider');

				if (gradeOverrides[grade]) {
					assessmentProvider = gradeOverrides[grade];
				}

				return {
					defaultAssessmentProvider: assessmentProvider ? assessmentProvider : AssessmentProviders.ILE,
					assessmentProviders: allOrgs.reduce((data, org) => {
						const assessmentProvider = _.get(org, 'settings.AssessmentProvider');
						return [...data, ...(!!assessmentProvider ? [assessmentProvider] : [])];
					}, []),
					gradeOverrides
				};
			});
	}
}
